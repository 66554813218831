import React from "react"
import { Layout, Iframe } from "../components"

const JoinUs = () => (
  <Layout>
    <Iframe id={process.env.GATSBY_RESUME_FORM_ID} />
  </Layout>
)

export default JoinUs
